export const CustomTableStyle = {
    documentBodyStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1rem',
        padding: '1.5rem',
        backgroundColor: 'common.black',
        height: 'auto'
    },
    tableBodyStyle: {
        padding: '16px 10px 16px 0px',
    },
    paperStyle: {
        maxHeight: "calc(100% - 40px)", 
        display: "flex", 
        flexDirection: "column", 
        width: '100%',
    },
    tableTopStyle: {
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        height: "88px",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        padding: 2,
        boxShadow: 'none'
    },
    searchStyle: {
        height: "56px",
        width: "600px"
    },
    iconContainer: {
        height: "40px",
        width: "40px",
        padding: "8px"
    },
    tableTopButtons: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: '8px'
    },
    searchButtonStyle: { 
        width: "189px", 
        height: "42px" 
    },
    sortOptionsIconsStyle: {
        width: "40px",
        height: "24px",
        paddingRight: "16px"
    },
    printButtonStyle: {
        width: "max-content",
        maxHeight: "36px"
    },
    filterButtonContainer: {
        width: "100%", 
        justifyContent: "space-between",
        display: "flex"
    }, 
    columnMenuStyle: {
        backgroundColor: "background.default", 
        border: "none", 
        display: 'flex', 
        flexDirection: "column", 
        r: '2rem'
    },
    menuItemsStyle: {
        '&:hover': {
            cursor: 'auto',
            background: 'none'
        }
    },
    TableStyle: {
        overFlowX: 'scroll', width: "100%", 
        paddingBottom: 1, 
        borderRadius: 0
    }, 
    tableCellBox: {
        height: "100%", 
        width: "100%", 
        display: "flex", 
        alignItems: "center"
    },
    menuSlotProps: {
        width: '220px', 
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 4%);", 
        backgroundColor: "background.default", 
        border: "none", 
        display: 'flex', 
        flexDirection: "column"
    },
    typographyStyle: {
        fontSize: "14px",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    tableFooterStyle: {
        display: "flex", 
        alignItems: "center"
    },
    customSearchStyle: {
        width: "600px", 
        display: "flex", 
        alignItems: "center", 
        gap: '0.5rem'
    },
    tableHeadIcon: {
        marginRight: 2, 
        height: "20px",
         width: "20px", 
        color: 'text.secondary'
    },
    tableSortStyle: {
        width: "100%", 
        fontSize: '0.875rem', 
        pl: '1rem',
    },
    tableSortRoot: {
        display: "flex", 
        justifyContent: "space-between", 
        paddingLeft: 0
    }
}