/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import { urls } from '../urls'
import dayjs from 'dayjs';

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

export const useDocumentData = (registrationId: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [applicantDocs, setApplicantDocs] = useState<any>([]);
    const transactionUrl = urls.TRANSACTION;
    const controller = new AbortController();
    const documentsUrl = `${process.env.NODE_ENV === 'production' ? transactionUrl : ""}/documentService`

    useEffect(() => {
        setLoading(true);
        const { signal } = controller;

        const fetchApplicantDocs = async () => {
            try {
                const listOfDocs = await (await fetch(`${documentsUrl}/getDocumentService/${registrationId}`, {
                    method: 'GET',
                    credentials: "include",
                    signal
                })).json();

                const transformData = listOfDocs.documents.map((data) => {
                    return {
                        key: data.fileName,
                        fileName: data.fileName,
                        date: dayjs(data.insertDate).format("MM/DD/YYYY hh:mm:ss A"),
                        fileSize: "5 MB",
                        userName: "joseph.park"
                    }
                })

                setApplicantDocs(transformData)
                setLoading(false);
            } catch (err) {
                console.error("error fetching applicant's documents:",err);
                setLoading(false);
            }
        }
        fetchApplicantDocs();

        return () => controller.abort()

    }, [registrationId, transactionUrl]);

    return { applicantDocs, loading };
}
