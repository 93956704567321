import { create } from "zustand"
import { persist, createJSONStorage } from 'zustand/middleware'

export interface ApplicantDetailsState {
    tcn: string
    ori: string
    incompleteStatuses: string[]
    setTcn: Function
    setOri: Function
    setIncompleteStatuses: Function
    documentSelected: boolean
    setDocumentSelected: Function
    docs: Array<any>
    setDocs: Function
}

const initialState: ApplicantDetailsState = {
    tcn: "",
    ori: "",
    incompleteStatuses: [],
    documentSelected: false,
    docs: [],
    setTcn: () => { },
    setOri: () => { },
    setIncompleteStatuses: () => {},
    setDocumentSelected: () => {},
    setDocs: () => {

    }
}

export const useApplicantDetailsStore = create<ApplicantDetailsState>((set, get) => ({
    ...initialState,
    setTcn: (tcn: string) => set({ tcn }),
    setOri: (ori: string) => set({ ori }),
    setIncompleteStatuses: (incompleteStatuses: string[]) => set({incompleteStatuses}),
    setDocumentSelected: (documentSelected: boolean) => set({documentSelected}),
    setDocs: (docs: object[]) => set({docs})
}))